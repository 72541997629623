import { Dispatch, SetStateAction, useEffect, useState } from "react";

/**
 * Similar usage to useState, but with persistent state using browser's localStorage
 * ```
 * const [user, setUser] = useLocalStorage('user', {name:"John Doe", age:40} )
 * ```
 * @param key key used to get/set the localStorage
 * @param initialValue value to initiate with if the key does not currently exist in storage
 * @returns [value, setValue] from  useState hook
 */
export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(() => {
    const storageValue = localStorage.getItem(key);
    if (storageValue === null) return initialValue;
    try {
      const jsonValue = JSON.parse(storageValue);
      return jsonValue;
    } catch {
      return storageValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
