import {
  baseOidcConfig,
  POST_LOGIN_PATH_SESSION_STORAGE_KEY,
} from "core/configs";
import { useExtensoToken } from "core/hooks/useExtensoToken";
import { useRouter } from "next/router";
import { AuthProvider, AuthProviderProps } from "oidc-react";
import { ReactNode } from "react";
import { AvailableRoutes } from "resources/availableRoutes";

const excludedPaths: AvailableRoutes[] = ["/signin-oidc", "/logout"];

function shouldSaveRedirectPath(routerPathname: AvailableRoutes) {
  return !excludedPaths.includes(routerPathname);
}

export default function Authorize({ children }: { children: ReactNode }) {
  const { query, asPath, replace, pathname } = useRouter();
  const { token: extensoToken } = useExtensoToken();

  const logoutPathName: AvailableRoutes = "/logout";
  const isLogoutPage = pathname === logoutPathName;

  const extensoRedirectPathname: AvailableRoutes = "/extenso-redirect";
  const isExtensoRedirectPage = pathname === extensoRedirectPathname;

  const isFromExtenso = isExtensoRedirectPage || Boolean(extensoToken);

  let autoSignIn = true;
  if (isFromExtenso || isLogoutPage) {
    autoSignIn = false;
  }

  const configWithRedirects: AuthProviderProps = {
    ...baseOidcConfig,

    redirectUri: `${window.location.origin}/signin-oidc`,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
    autoSignIn,
    onBeforeSignIn() {
      if (shouldSaveRedirectPath(pathname as AvailableRoutes)) {
        const path =
          query.maintain_locale === "true" ? window.location.pathname : asPath;
        sessionStorage.setItem(POST_LOGIN_PATH_SESSION_STORAGE_KEY, path);
        return path;
      }
      return "/";
    },
    async onSignIn() {
      const postLoginPath = sessionStorage.getItem(
        POST_LOGIN_PATH_SESSION_STORAGE_KEY
      );
      await replace(postLoginPath ?? "/");
    },
  };

  return <AuthProvider {...configWithRedirects}>{children}</AuthProvider>;
}
