import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import { useUserInfo } from "core/hooks/useUserInfo";
import { getAuthUrl } from "core/utils/getAuthUrl";

export function UserAvatar({
  width,
  height,
  id,
}: {
  width?: number;
  height?: number;
  id?: string | undefined;
}) {
  const { data } = useUserInfo();
  const currentId = id ?? data?.id;
  if (!currentId)
    return (
      <Avatar sx={{ width, height }}>
        <PersonIcon />
      </Avatar>
    );
  const src = getAuthUrl(`/api/account/profile-picture-file/${currentId}`);
  return <Avatar src={src} sx={{ width, height }} />;
}
