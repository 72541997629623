import { FixHubPermissions } from "resources/permissions";
import { useUserPermissionContextsInCurrentBrandRegion } from "./useUserPermissionContextsInCurrentBrandRegion";

export function usePermission(policyKey: FixHubPermissions) {
  const data = useUserPermissionContextsInCurrentBrandRegion();
  if (policyKey.length === 0) return true;
  if (!data) return false;
  const permissions = new Set(
    data.flatMap((permContext) => permContext.permissions)
  );

  return permissions.has(policyKey);
}
