import { useRouter } from "next/router";
import { useCurrentTranslationExtractor } from "features/DMS/hooks/useCurrentTranslationExtractor";
import { useCallback, useMemo } from "react";
import type { IMenuItem, MenuItemType } from "core/entities";
import { IDocLibraryDto } from "features/DMS/entities";
import { useAPIAllImmutable } from "core/hooks/useAPIAllImmutable";
import {
  CategoryMenuItem,
  MenuLibraryItem,
  MenuLink,
  RootMenuItem,
} from "./defs";

export const URL_UNDEFINED_CHARACTER = "#";

function processCategory({
  id,
  displayName,
  target,
  url,
  submenus,
}: IMenuItem): CategoryMenuItem {
  const processedLinks: MenuLink[] = submenus
    .filter((entry): entry is WithRequired<IMenuItem, "url"> =>
      Boolean(entry.url)
    )
    .map((item) => ({
      id: item.id,
      displayName: item.displayName,
      url: item.url,
      target: item.target,
    }));

  return {
    id,
    displayName,
    target,
    url: url === URL_UNDEFINED_CHARACTER ? undefined : url,
    links: processedLinks,
  };
}

/**
 * @param menuItemType defaults to "Header"
 */
export function useMobileMenuItems(menuItemType: MenuItemType = "Header") {
  const { locale } = useRouter();
  const translationExtractor = useCurrentTranslationExtractor();
  const processLibraries = useCallback(
    (libraries: IDocLibraryDto[]): MenuLibraryItem[] =>
      libraries.map(({ id, translations }) => {
        const { name } = translationExtractor(translations) || {};
        return { id, displayName: name! };
      }),
    [translationExtractor]
  );
  const { data, isLoading, isError } = useAPIAllImmutable<IMenuItem[]>({
    path: "menu-item",
    params: { locale },
  });
  const menuItems: RootMenuItem[] = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    const rootItems: RootMenuItem[] = data
      .filter((item) => {
        const isCorrectType = item.menuItemType === menuItemType;
        const shouldDisplay =
          item.menuItemType !== "Header" ||
          (Array.isArray(item.libraries) && item.libraries.length > 0) ||
          item.submenus.length > 0 ||
          item.url !== "#";
        return isCorrectType && shouldDisplay;
      })
      .map((rootItem) => {
        const processedLibraries = Array.isArray(rootItem.libraries)
          ? processLibraries(rootItem.libraries)
          : [];
        const processedCategories = rootItem.submenus
          .map((item) => processCategory(item))
          .filter((category) => category.links.length > 0 || category.url);
        return {
          id: rootItem.id,
          libraries: processedLibraries,
          displayName: rootItem.displayName,
          target: rootItem.target,
          url:
            rootItem.url === URL_UNDEFINED_CHARACTER ? undefined : rootItem.url,
          categories: processedCategories,
        };
      });
    return rootItems;
  }, [data, processLibraries, menuItemType]);

  return { isLoading, isError, data, menuItems };
}
