import { brandRegionConfig } from "brandRegionConfig";
import { BrandRegionCode, RegionCode } from "core/entities";

export const brandRegionCodeRegionCodeMap = new Map<
  BrandRegionCode,
  RegionCode
>();

Object.values(brandRegionConfig).forEach(({ regionInfos }) => {
  regionInfos.forEach(({ regionCode, marketCode }) => {
    brandRegionCodeRegionCodeMap.set(marketCode, regionCode);
  });
});

export function getRegionCodeFromBrandRegionCode(
  brandRegionCode: BrandRegionCode
): RegionCode {
  return brandRegionCodeRegionCodeMap.get(brandRegionCode) as RegionCode;
}
