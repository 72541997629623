export function chunkify(array: any[], chunkSize: number = 10) {
  const chunks = Array.from(
    { length: Math.ceil(array.length / chunkSize) },
    (_, i) => {
      const start = chunkSize * i;
      return array.slice(start, start + chunkSize);
    }
  );
  return chunks;
}
