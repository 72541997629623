import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { SUPPORT_EMAIL } from "config";
import { PageHeader } from "core/components/PageHeader";
import { useRegion } from "core/hooks/useRegion";
import { Trans, useTranslation } from "react-i18next";

export function UnauthorizedContextPage() {
  const region = useRegion();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:UnauthorizedContextPage",
  });
  const { t: regionT } = useTranslation("Fixhub", {
    keyPrefix: "Region:RegionName",
  });
  const subTitle = region
    ? t("region", { region: regionT(region) })
    : undefined;

  return (
    <Box component="main">
      <PageHeader
        title={t("pageTitle")}
        titleTypographyProps={{ color: "error.main" }}
        subTitle={subTitle}
        subTitleTypographyProps={{ color: "error.main", variant: "h6" }}
      />
      <Typography mb={4}>
        <Trans i18nKey="Next:Core:UnauthorizedContextPage.message">
          Something isn&apos;t right? Please contact your supervisor or
          <MuiLink
            color="primary.main"
            fontWeight="bold"
            underline="none"
            href={`mailto: ${SUPPORT_EMAIL}`}
          >
            {SUPPORT_EMAIL}
          </MuiLink>
        </Trans>
      </Typography>
    </Box>
  );
}
