import { Theme } from "@mui/material/styles";
import { i18nLocales } from "config";
import type {
  BrandCode,
  BrandRegionCode,
  RegionCode,
  StateCode,
  SubRegionCode,
} from "brandRegionConfig";

interface LogoObject<T> {
  default: T;
  desktop?: T;
  mobile?: T;
}

export type LogoConfig<T> = LogoObject<T> & {
  locales?: Partial<Record<I18nLocale, LogoObject<T>>>;
};

export const BRAND_TYPES = {
  service: "SERVICE",
  collision: "COLLISION",
  glassRepair: "GLASS_REPAIR",
  headOffice: "HEAD_OFFICE",
} as const;

export type BrandType = (typeof BRAND_TYPES)[keyof typeof BRAND_TYPES];

export interface Brand {
  brandType: BrandType;
  theme: Theme;
  name: string;
  brandCode: BrandCode;
}

export type I18nLocale = (typeof i18nLocales)[number];
export { BrandCode, BrandRegionCode, RegionCode, StateCode, SubRegionCode };
