import { useCallback, useMemo } from "react";
import { getBrandCode } from "core/utils/getBrandCode";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useRouter } from "next/router";
import { BareFetcher, PublicConfiguration } from "swr/_internal";
import { APIError } from "core/entities";
import { useAuth } from "oidc-react";
import { useAPI } from "./useAPI";
import { useDebouncedCallback } from "./useDebouncedCallback";
import { useLocalStorage } from "./useLocalStorage";
import { useTrackException } from "./useTrackException";
import { useAPIPutJSON } from "./useAPIPutJSON";

type ValueSetter<T> = (
  val: T | ((val: T) => T | T),
  callback?: Function
) => Promise<void>;

interface Scope {
  brand?: boolean;
  region?: boolean;
}

function useBuildKey(key: string, scope: Scope) {
  const { query } = useRouter();
  const currentBrandCode = getBrandCode();
  if (scope.region && !query.region) {
    throw new Error("Region is not defined in the query string");
  }
  const currentRegion = String(query.region);
  const brandPart = scope.brand ? `${currentBrandCode}-` : "";
  const regionPart = scope.region ? `${currentRegion}-` : "";
  return `${brandPart}${regionPart}${key}`;
}

/**
 * Custom React hook that provides functionality for storing and retrieving user data.
 * @param {string} key - The key under which the data is stored.
 * @param {T} defaultValue - The default value to be returned if no value is found in the cache.
 */
export function useUserSetting<
  T extends number | string | Record<any, any> | boolean
>(
  key: string,
  defaultValue: T,
  scope: Scope = {},
  options?: Partial<
    PublicConfiguration<{ value: T }, APIError, BareFetcher<{ value: T }>>
  >
): {
  value: T;
  setValue: ValueSetter<T>;
  localStorageValue: T;
  databaseValue: T | undefined;
  isLoading: boolean;
  isError: APIError | undefined;
} {
  const trackException = useTrackException();
  const { userData } = useAuth();
  const isUserLoggedIn = Boolean(userData);
  const put = useAPIPutJSON();
  const fullKey = useBuildKey(key, scope);
  const path = "/api/app/user-settings";
  const url = useMemo(
    () => (isUserLoggedIn ? { path, params: { key: fullKey } } : null),
    [isUserLoggedIn, fullKey, path]
  );
  const [localStorageValue, setLocalStorageValue] = useLocalStorage<T>(
    `user-storage.${fullKey}`,
    defaultValue
  );
  const { data, mutate, isError, isLoading } = useAPI<{ value: T }>(url, {
    revalidateIfStale: false,
    onError: async (error) => {
      const requestBody = { value: localStorageValue, key: fullKey };
      try {
        if (error.status !== 404) {
          throw new Error(`API Error status: ${error.status}`);
        }
        await put(path, requestBody);
      } catch (e) {
        trackException({
          exception: e as Error,
          severityLevel: SeverityLevel.Error,
          properties: {
            function: "useUserSetting.useApi.onError",
            key: url,
            value: localStorageValue,
            requestBody,
            apiError: error,
          },
        });
      }
    },
    onSuccess: ({ value }) => {
      if (value !== localStorageValue) {
        setLocalStorageValue(value);
      }
    },
    ...options,
  });
  const localUpdate = useCallback(
    async (val: T) => {
      await mutate({ value: val }, { revalidate: false });
    },
    [mutate]
  );

  const debouncedPersistentUpdate = useDebouncedCallback(
    async (val: T, callback?: Function) => {
      const requestBody = { value: val, key: fullKey };
      try {
        setLocalStorageValue(val);
        await mutate(put(path, requestBody), {
          populateCache: false,
          revalidate: false,
          optimisticData: requestBody,
          rollbackOnError: true,
        });
        if (callback && typeof callback === "function") callback();
      } catch (e) {
        trackException({
          exception: e as Error,
          severityLevel: SeverityLevel.Error,
          properties: {
            function: "useUserSetting.persistentUpdate",
            key: url,
            value: val,
            requestBody,
            error: e,
          },
        });
      }
    },
    [mutate, put, setLocalStorageValue, fullKey, url, trackException],
    500
  );

  const setValue: ValueSetter<T> = useCallback(
    (valOrFunction, callback) => {
      const newValue =
        typeof valOrFunction === "function"
          ? valOrFunction(data!.value)
          : valOrFunction;
      debouncedPersistentUpdate(newValue, callback);
      return localUpdate(newValue);
    },

    [data, localUpdate, debouncedPersistentUpdate]
  );
  const databaseValue = data?.value;
  const value = databaseValue ?? localStorageValue;

  return {
    value,
    setValue,
    databaseValue,
    localStorageValue,
    isLoading,
    isError,
  };
}
