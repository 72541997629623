import { IDS_URL } from "config";
import { AuthProviderProps } from "oidc-react";

const baseOidcConfig: AuthProviderProps = {
  authority: IDS_URL,
  clientId: "Next_App",
  scope: "openid profile Fixhub",
  responseType: "code",
};

export default baseOidcConfig;
