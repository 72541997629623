import { Brand, BrandCode } from "core/entities";
import BaseThemeObject from "core/theme/BaseThemeObject";
import { getBrandCode } from "core/utils/getBrandCode";
import BrandMap from "resources/BrandMap";
import { createTheme } from "@mui/material/styles";
import { brandRegionConfig } from "brandRegionConfig";

export const fallbackBrand: Brand = {
  name: "Pitstop",
  theme: createTheme(BaseThemeObject),
  brandCode: "all" as BrandCode,
  brandType: "HEAD_OFFICE",
};

export function useBrand(brandCode?: BrandCode): Brand {
  if (brandCode) {
    const brand = BrandMap.get(brandCode);
    if (!brand) {
      throw new Error(`Brand ${brandCode} not found`);
    }
    return brand;
  }
  const windowBrandCode = getBrandCode();
  if (!windowBrandCode) {
    throw new Error(
      `no brandCode supplied and could not determine the brandCode from the window location`
    );
  }
  const brand = BrandMap.get(windowBrandCode);
  if (!brand) {
    throw new Error(`Brand ${brandCode} not found`);
  }
  return brand;
}

export function useBrandStateCodes(brandCode?: BrandCode) {
  if (brandCode) {
    return brandRegionConfig[brandCode].stateCodes;
  }
  const windowBrandCode = getBrandCode();
  if (!windowBrandCode) return [];
  return brandRegionConfig[windowBrandCode].stateCodes;
}
