import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { useDebouncedCallback } from "core/hooks/useDebouncedCallback";
import { useNotifications } from "./useNotifications";
import { Notification } from "./NotificationDefs";

export function useNotificationRouteWatcher() {
  const firstLoad = useRef(true);
  const router = useRouter();
  const { notifications, markNotificationAsRead } = useNotifications();

  const debouncedMarkRouteAsReadIfUnread = useDebouncedCallback(
    async (route: string, currentNotifications: Notification[]) => {
      if (currentNotifications.length === 0) return;
      const unReadNotifications = currentNotifications.filter((not) => {
        if (not.isRead) return false;
        if (!not.uri) return false;
        const uriRegex = new RegExp(`${not.uri}$`, "i");
        return uriRegex.test(route);
      });
      if (unReadNotifications.length === 0) return;
      try {
        await Promise.allSettled(
          unReadNotifications.map((not) => markNotificationAsRead(not.id))
        );
      } catch {
        // Do nothing
      }
    },
    [markNotificationAsRead],
    500
  );
  useEffect(() => {
    if (firstLoad.current && notifications.length > 0) {
      firstLoad.current = false;
      debouncedMarkRouteAsReadIfUnread(router.asPath, notifications);
    }
    const eventArgs: Parameters<typeof router.events.on> = [
      "routeChangeComplete",
      (url: string) => {
        debouncedMarkRouteAsReadIfUnread(url, notifications);
      },
    ];
    router.events.on(...eventArgs);
    return () => {
      router.events.off(...eventArgs);
    };
  }, [debouncedMarkRouteAsReadIfUnread, notifications, router]);
}
