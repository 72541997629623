import { useRef, useCallback, DependencyList } from "react";

export function useDebouncedCallback<TArgs extends any[]>(
  fn: (...args: TArgs) => void,
  deps: DependencyList,
  delay: number
) {
  const timeout = useRef<NodeJS.Timeout | undefined>();

  const callbackFunction = useCallback(
    (...args: TArgs) => {
      const setTimeoutCallback = () => {
        clearTimeout(timeout.current);
        fn(...args);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(setTimeoutCallback, delay);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, delay]
  );
  return callbackFunction;
}
