import { useMemo } from "react";
import { useAPI } from "core/hooks/useAPI";
import { useCurrentTranslationExtractor } from "./useCurrentTranslationExtractor";
import { IDocLibraryDto } from "../entities";

export function useLibraryById(id?: string) {
  const translationExtractor = useCurrentTranslationExtractor();
  const key = id ? `doc-library/${id}` : null;
  const { data, isError, isLoading, mutate } = useAPI<IDocLibraryDto>(key);
  const dataWithCurrentTranslation = useMemo(() => {
    if (isLoading || isError || !data) return undefined;
    const { name, description, transKey } =
      translationExtractor(data.translations) || {};
    return { ...data, name, description, transKey };
  }, [translationExtractor, data, isLoading, isError]);

  return {
    data: dataWithCurrentTranslation,
    isError,
    isLoading,
    mutate,
  };
}
