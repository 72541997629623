import { ThemeOptions } from "@mui/material/";
import { createTheme } from "@mui/material/styles";
import deepmerge from "@mui/utils/deepmerge";
import BaseThemeObject from "./BaseThemeObject";

const ExtensoThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#006BB6",
    },
    secondary: {
      main: "#f33d48",
    },
    warning: {
      main: "#ef3e43",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  shape: {
    borderRadius: 4, // reset to default value
  },
};

export const ExtensoTheme = createTheme(
  deepmerge(BaseThemeObject, ExtensoThemeOptions)
);
