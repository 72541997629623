import { ReactNode, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FilterContext } from "core/context/FilterContext";
import { useScrollTopOnNavigation } from "core/hooks/useScrollTopOnNavigation";
import { ComponentErrorBoundary } from "../ComponentErrorBoundary";
import { Footer } from "../Footer";
import { GlobalHeader } from "../GlobalHeader";

interface StandardLayoutProps {
  children: ReactNode;
}

export function StandardLayout({ children }: StandardLayoutProps) {
  const filterContextValue = useState(false);
  const containerRef = useScrollTopOnNavigation();
  const [isFilterOn] = filterContextValue;
  return (
    <FilterContext.Provider value={filterContextValue}>
      <Box display="grid" height="100vh" gridTemplateRows="auto 1fr auto">
        <ComponentErrorBoundary>
          <GlobalHeader />
        </ComponentErrorBoundary>
        <Stack
          ref={containerRef}
          overflow="auto"
          height="100%"
          bgcolor="background.default"
          sx={{
            filter: isFilterOn ? "brightness(0.75)" : undefined,
            transition: "filter 500ms ease",
          }}
        >
          <Box sx={{ flex: 1, paddingBlockStart: 2, paddingBlockEnd: 4 }}>
            {children}
          </Box>
          <ComponentErrorBoundary>
            <Footer />
          </ComponentErrorBoundary>
        </Stack>
      </Box>
    </FilterContext.Provider>
  );
}
