import { BrandRegionCode } from "core/entities";
import { useAPIAll } from "core/hooks/useAPIAll";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IShopDto } from "resources/APIModel";
import { getRegionCodeFromBrandRegionCode } from "core/utils/getRegionCodeBrandRegionCode";
import { getBrandCode } from "core/utils/getBrandCode";
import { ALL_VALUE } from "config";

export function useShopOptions(brandShopsOnly: boolean = false) {
  const brandCode = getBrandCode();
  const { data, isLoading, isError } = useAPIAll<IShopDto[]>(
    "/api/app/shop/brands-shops"
  );

  const { t: brandTranslation } = useTranslation("Fixhub", {
    keyPrefix: "Brand:BrandName",
  });
  const { t: dmsTranslation } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:useShopOptions",
  });

  const shopOptions = useMemo(() => {
    if (!Array.isArray(data))
      return [{ label: dmsTranslation("allShops"), value: ALL_VALUE }];
    const options = data
      .filter(
        (
          shop
        ): shop is {
          shopName: string;
          brandCode: string;
          fN_ShopId: string;
        } => {
          const {
            shopName,
            brandCode: shopBrandCode,
            fN_ShopId: FnShopId,
          } = shop;
          if (!shopName || !shopBrandCode || !FnShopId) return false;
          if (!brandShopsOnly) return true;
          return shopBrandCode.toLowerCase() === brandCode?.toLowerCase();
        }
      )
      .map((shop) => ({
        label: `${shop.shopName} (${brandTranslation(
          shop.brandCode.toLowerCase()
        )})`,
        value: shop.fN_ShopId,
      }));
    return [
      { label: dmsTranslation("allShops"), value: ALL_VALUE },
      ...options,
    ];
  }, [data, dmsTranslation, brandShopsOnly, brandCode, brandTranslation]);

  const shopBrandRegionMap = useMemo(() => {
    if (!Array.isArray(data)) return {};
    const mapping = data.reduce((map, currentShop) => {
      if (
        currentShop.fN_ShopId &&
        currentShop.marketCode &&
        currentShop.brandCode
      ) {
        const brand = currentShop.brandCode.toLowerCase();
        const region = getRegionCodeFromBrandRegionCode(
          currentShop.marketCode.toLowerCase() as BrandRegionCode
        );
        if (brand && region) {
          map.set(currentShop.fN_ShopId, {
            brand,
            region,
          });
        }
      }
      return map;
    }, new Map());
    return Object.fromEntries(mapping) as Record<
      string,
      { brand: string; region: string }
    >;
  }, [data]);

  return { data, isLoading, isError, shopOptions, shopBrandRegionMap };
}
