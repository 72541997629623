import { usePermissionChecker } from "core/hooks/usePermissionChecker";
import { useManageableLibraries } from "features/DMS/hooks";

export function useCanManage() {
  const { data } = useManageableLibraries();
  const { hasSomePermissions, hasAllPermissions } = usePermissionChecker();
  const canManageLibraryContent = data?.totalCount && data?.totalCount > 0;

  const hasLibraryCrud = hasSomePermissions([
    "Fixhub.DocLibraries.Create",
    "Fixhub.DocLibraries.Edit",
    "Fixhub.DocLibraries.Delete",
  ]);

  const canManageNews = hasSomePermissions([
    "NewsEvents.Articles.Create",
    "NewsEvents.Articles.Edit",
    "NewsEvents.Articles.Delete",
    "NewsEvents.Categories.Create",
    "NewsEvents.Categories.Edit",
    "NewsEvents.Categories.Delete",
  ]);

  const canManageCmsBlogPosts = hasAllPermissions([
    "CmsKit.BlogPosts.Create",
    "CmsKit.BlogPosts.Update",
    "CmsKit.BlogPosts.Delete",
    "CmsKit.BlogPosts.Publish",
  ]);

  return (
    canManageLibraryContent ||
    hasLibraryCrud ||
    canManageCmsBlogPosts ||
    canManageNews
  );
}
