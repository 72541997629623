import { createTheme } from "@mui/material/styles";
import deepmerge from "@mui/utils/deepmerge";
import type { ThemeOptions } from "@mui/material";
import { green, grey, orange } from "@mui/material/colors";
import BaseThemeObject from "./BaseThemeObject";

const HalloweenThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: orange[600],
    },
    secondary: {
      main: green[600],
    },
    warning: {
      main: "#ef3e43",
    },
    background: {
      paper: grey[900],
      default: grey[800],
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#ffffff99",
    },
  },
};

export const HalloweenTheme = createTheme(
  deepmerge(BaseThemeObject, HalloweenThemeOptions)
);
