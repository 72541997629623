import { useAPIFetch } from "core/hooks/useAPIFetch";
import { useToaster } from "core/hooks/useToaster";
import { useTranslation } from "react-i18next";

export function useDownloadDocumentByFileId() {
  const apiFetch = useAPIFetch();
  const { errorToast } = useToaster();
  const { t } = useTranslation("Fixhub");
  return async (
    fileId: string,
    fileName: string,
    openInNewTab: boolean = false
  ) => {
    try {
      const res = await apiFetch(`doc-document/file/${fileId}`);
      const blob = await res.blob();
      const fileURL = window.URL.createObjectURL(blob);
      const a = document.createElement("a") as HTMLAnchorElement;
      a.href = fileURL;
      a.target = "_blank";
      if (!openInNewTab) {
        a.download = fileName;
      }
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch {
      errorToast(t("Next:Core:useDownloadDocumentByFileId.failure"));
    }
  };
}
