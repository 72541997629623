import { TranslationKey } from "../entities";
/**
 * @returns a translation object in the current locale, otherwise defaults to the first available translation
 */
export function extractCurrentTranslation<
  T extends { transKey: TranslationKey }
>(translations: T[], locale: TranslationKey): T | undefined {
  return (
    translations?.find(
      (currentTranslation) => currentTranslation?.transKey === locale
    ) || translations[0]
  );
}
