export const pageItemIdPathMap = {
  "privacy-policy": "/privacy-policy",
  "email-request": "/email-request",
  "email-signature": "/email-signature",
  "payment-request": "/payment-request",
  "training-request": "/training-request",
  "all-news": "/news",
  "all-events": "/events",
  "manage-libraries": "/dms/library",
  "extenso-report-list": "/extenso",
  "reports_shop-performance": "/reports/shop-performance",
  "reports_vehicle-location": "/reports/vehicle-location",
  "shop-profile": "/shop-profile",
  "contact-us": "/contact-us",
  "reports_waiting-list": "/reports/waiting-list",
  "cms-editor-list": "/cms-editor",
  "cms-editor-create": "/cms-editor/create",
  "shop-hours-management": "/shop-hours-management",
  "publication-management": "/manage/publications",
  "publication-article-edit": "manage/publications/articles/[id]",
  "publication-label-edit": "manage/publications/labels/[id]",
  "publication-category-edit": "manage/publications/category/[id]",
  "publication-article-create": "manage/publications/articles/create",
  "publication-label-create": "manage/publications/labels/create",
  "publication-category-create": "manage/publications/categories/create",
  manage: "/manage",
  logout: "/logout", // TODO support "regionless" paths
  search: "/search",
  home: "/",
} as const;

export type PageId = keyof typeof pageItemIdPathMap;
