import jwt_decode from "jwt-decode";
import { useToken } from "./useToken";

export function useUserTokenInfo() {
  const token = useToken();
  if (!token) return undefined;
  const userInfo: any = jwt_decode(token);
  const name = userInfo.given_name;
  const surname = userInfo.family_name;
  const userName = userInfo.unique_name;
  const fullName = name && surname ? `${name} ${surname}` : undefined;
  return { ...userInfo, name, surname, userName, fullName };
}
