import ArticleIcon from "@mui/icons-material/Article";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import {
  FileIcon,
  defaultStyles as fileIconStyles,
  DefaultExtensionType,
  FileIconProps,
} from "react-file-icon";

const extensionRemap: Record<string, string> = {
  xlsm: "xls",
};

const styleOverrides: Partial<
  Record<DefaultExtensionType, Partial<FileIconProps>>
> = {
  pdf: { glyphColor: "#D93831" },
};

export interface SvgFileIconProps extends SvgIconProps {
  fileName: string;
}

export function SvgFileIcon({ fileName, sx, ...rest }: SvgFileIconProps) {
  const extension = fileName.split(".").at(-1);
  if (!extension)
    return (
      <ArticleIcon
        sx={{ fontSize: "1.5rem", ...sx }}
        color="inherit"
        {...rest}
      />
    );
  const lowerCaseExtension = extension.toLowerCase();
  const ext = (extensionRemap[lowerCaseExtension] ??
    lowerCaseExtension) as DefaultExtensionType;
  const extensionStyles: Partial<FileIconProps> = fileIconStyles[ext];

  return (
    <SvgIcon sx={{ fontSize: "1.5rem", ...sx }} color="inherit" {...rest}>
      <FileIcon
        extension={extension}
        {...extensionStyles}
        {...styleOverrides[ext]}
        labelUppercase
      />
    </SvgIcon>
  );
}
