import { ReactNode } from "react";
import { usePermissionChecker } from "core/hooks/usePermissionChecker";
import { useRouter } from "next/router";
import { routeAccessMap } from "resources/RouteAccessMap";
import { AvailableRoutes } from "resources/availableRoutes";
import { Unauthorized401Page } from "core/pages/Unauthorized401Page";

export function PagePermissionChecker({ children }: { children: ReactNode }) {
  const { hasSomePermissions } = usePermissionChecker();
  const { pathname } = useRouter();
  const { requiredPermissions = [], disabled: pageDisabled } =
    routeAccessMap.get(pathname as AvailableRoutes) || {};
  const hasRequiredPagePermissions = hasSomePermissions(requiredPermissions);
  const unauthorized = pageDisabled || !hasRequiredPagePermissions;

  if (unauthorized) {
    return <Unauthorized401Page />;
  }
  return <>{children}</>;
}
