import "mock-service-worker/initMSW";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationErrorBoundaryPage } from "core/pages/ApplicationErrorBoundaryPage";
import { reactPlugin } from "appInsightsWeb";
import React, { useRef, useEffect, useState } from "react";
import Head from "next/head";
import App, { AppContext, AppInitialProps, AppProps } from "next/app";
import { GlobalLoader } from "core/components/GlobalLoader";
import { Layout } from "core/components/Layout";
import Authorize from "core/components/Authorize";
import { ExtensoTokenChecker } from "core/components/ExtensoTokenChecker";
import { PagePermissionChecker } from "core/components/PagePermissionChecker";
import { PageErrorBoundary } from "core/components/PageErrorBoundary";
import { LoginLogoutBypass } from "core/components/LoginLogoutBypass";
import { MUIThemeProvider } from "core/components/MUIThemeProvider";
import { ConfigCheckWrapper } from "core/components/ConfigCheckWrapper";
import CurrentBrandRegionContextProvider from "core/context/CurrentBrandRegionContext";
import { DatePickerLocalizationProvider } from "core/components/DatePickerLocalizationProvider";
import ExtensoTokenProvider from "core/context/ExtensoContext";
import { I18nextProvider } from "react-i18next";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import CssBaseline from "@mui/material/CssBaseline";
import { REVALIDATE_IF_STALE } from "config";
import { SWRConfig } from "swr";
import type { BrandCode, RegionCode } from "core/entities";
import { ApplicationLoadingDisplay } from "core/components/ApplicationLoadingDisplay";
import { AvailableRegionsContextProvider } from "core/context/AvailableRegionsContext";
import { ApplicationConfigurationErrorPage } from "core/pages/ApplicationConfigurationErrorPage";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { CookieSync } from "core/components/CookieSync";
import { GlobalStylesComponent } from "core/components/GlobalStylesComponent";
import { RouterLocale } from "core/components/RouterLocale";
import { Toaster } from "core/components/Toaster";
import { usePostScrollHeightToParent } from "core/hooks/usePostScrollHeightToParent";
import i18n from "../i18n.config";

// Seems silly to expose the license key to the client, but that's how it's done apparently
// https://mui.com/components/data-grid/getting-started/#license-key-installation
LicenseInfo.setLicenseKey(
  "5c2bc399c2443b11a4d2d6c00c33d5d7Tz02ODc0MyxFPTE3MTg0NjQ1ODgyNjYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// https://mui.com/customization/how-to-customize/
// recommends hoisting to static variable to avoid rerendering
// the generated <style> tag
const GlobalCustomScrollbar = <GlobalStylesComponent />;

type PitstopConfigProps = {
  config:
    | {
        brandCode: BrandCode;
        availableRegions: RegionCode[];
      }
    | { error: { message: string } };
};

type PitstopAppProps = AppProps & PitstopConfigProps;

export default function PitstopApp({
  Component,
  pageProps,
  config,
}: PitstopAppProps) {
  const initialConfigRef = useRef(config);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  usePostScrollHeightToParent();

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  if (isInitialLoad) {
    if ("brandCode" in initialConfigRef.current) {
      return (
        <>
          {GlobalCustomScrollbar}
          <CssBaseline />
          <MUIThemeProvider brandCode={initialConfigRef.current.brandCode}>
            <ApplicationLoadingDisplay />
          </MUIThemeProvider>
        </>
      );
    }
    return (
      <>
        {GlobalCustomScrollbar}
        <CssBaseline />
        <ApplicationLoadingDisplay />
      </>
    );
  }

  if (
    typeof initialConfigRef.current === "object" &&
    "error" in initialConfigRef.current
  ) {
    reactPlugin.trackException({
      severityLevel: SeverityLevel.Error,
      properties: { component: "_app", config: initialConfigRef.current },
    });
    return (
      <>
        {GlobalCustomScrollbar}
        <CssBaseline />
        <ApplicationConfigurationErrorPage
          message={initialConfigRef.current.error.message}
        />
      </>
    );
  }

  const { brandCode, availableRegions } = initialConfigRef.current;

  return (
    <>
      {brandCode && (
        <Head>
          {brandCode && <link rel="icon" href={`/favicons/${brandCode}.png`} />}
        </Head>
      )}
      <ConfigCheckWrapper>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppInsightsErrorBoundary
            appInsights={reactPlugin}
            onError={ApplicationErrorBoundaryPage}
          >
            <AvailableRegionsContextProvider
              availableRegions={availableRegions}
            >
              <SWRConfig
                value={{
                  revalidateOnFocus: false,
                  revalidateIfStale: REVALIDATE_IF_STALE,
                  errorRetryCount: 5,
                }}
              >
                {GlobalCustomScrollbar}
                <CssBaseline />
                <I18nextProvider i18n={i18n}>
                  <ExtensoTokenProvider>
                    <MUIThemeProvider>
                      <DatePickerLocalizationProvider>
                        <Authorize>
                          <CookieSync />
                          <CurrentBrandRegionContextProvider>
                            <RouterLocale />
                            <Toaster>
                              <LoginLogoutBypass
                                Component={Component}
                                pageProps={pageProps}
                              >
                                <GlobalLoader>
                                  <ExtensoTokenChecker>
                                    <Layout>
                                      <PagePermissionChecker>
                                        <PageErrorBoundary>
                                          <Component {...pageProps} />
                                        </PageErrorBoundary>
                                      </PagePermissionChecker>
                                    </Layout>
                                  </ExtensoTokenChecker>
                                </GlobalLoader>
                              </LoginLogoutBypass>
                            </Toaster>
                          </CurrentBrandRegionContextProvider>
                        </Authorize>
                      </DatePickerLocalizationProvider>
                    </MUIThemeProvider>
                  </ExtensoTokenProvider>
                </I18nextProvider>
              </SWRConfig>
            </AvailableRegionsContextProvider>
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </ConfigCheckWrapper>
    </>
  );
}

PitstopApp.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps & PitstopConfigProps> => {
  // https://nextjs.org/docs/advanced-features/custom-app
  const appProps = await App.getInitialProps(context);
  const host = context.ctx.req?.headers?.host;
  if (host) {
    const { getPitstopConfig } = await import("core/utils/getPitstopConfig");
    const config = await getPitstopConfig(host);
    return { ...appProps, config };
  }
  return {
    ...appProps,
    config: { error: { message: "client side getInitialProps call" } },
  };
};
