import { useAuth } from "oidc-react";
import { useAPI } from "core/hooks/useAPI";
import { useAPIPutJSON } from "core/hooks/useAPIPutJSON";
import { usePermission } from "core/hooks/usePermission";
import { paths } from "resources/api-schema";

export type UserInfo =
  paths["/api/identity/users/{id}"]["get"]["responses"][200]["content"]["application/json"];

export type UpdateUserInfo = NonNullable<
  paths["/api/identity/users/{id}"]["put"]["requestBody"]
>["content"]["application/json"];

export function useUserInfo() {
  const { userData } = useAuth();
  const put = useAPIPutJSON();
  // TODO use a custom endpoint that exposes current user's info
  const canGetInfo = usePermission("AbpIdentity.Users");
  const key = userData?.profile?.sub && canGetInfo
    ? `/api/identity/users/${userData?.profile?.sub}`
    : null;
  // only fetch once
  const { data, isError, isLoading, mutate } = useAPI<UserInfo>(key, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  async function updateUserInfo(info: UpdateUserInfo) {
    if (!key) return;
    await put(key, info);
    await mutate();
  }

  return { data, isError, isLoading, mutate, updateUserInfo };
}
