import { useCallback } from "react";
import { useAPIFetch } from "./useAPIFetch";

export function useAPIPostEmpty() {
  const apiFetch = useAPIFetch();
  const post = useCallback(
    (path: string): Promise<Response> => apiFetch(path, { method: "POST" }),
    [apiFetch]
  );
  return post;
}
