import { ChangeEventHandler } from "react";
import TextField, {
  TextFieldProps as ITextFieldProps,
} from "@mui/material/TextField";
import { Controller, RegisterOptions, Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { SxProps, Theme } from "@mui/material";
import { StringMap, TOptions } from "i18next";

export interface ReactHookTextFieldProps {
  name: string;
  defaultValue?: string;
  label: string;
  rules?: Omit<
    RegisterOptions<any, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  required?: boolean;
  requiredAsterisk?: boolean;
  sx?: SxProps<Theme>;
  multiline?: boolean;
  minRows?: number;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  fullWidth?: boolean;
  maxRows?: number;
  TextFieldProps?: ITextFieldProps;
  tOptions?: string | TOptions<StringMap>;
  control?: Control<any, any>;
}
export function ReactHookTextField({
  name,
  defaultValue = "",
  label,
  rules = {},
  required = false,
  requiredAsterisk = true,
  multiline = false,
  minRows = 1,
  maxRows,
  disabled = false,
  sx = {},
  onChange,
  fullWidth = false,
  TextFieldProps = {},
  control,
}: ReactHookTextFieldProps) {
  const { t } = useTranslation();
  const labelPrefix = required && requiredAsterisk ? "* " : "";
  const actualLabel = `${labelPrefix}${label}`;
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{
        required: required
          ? t("Next:Core:FieldRequiredValidationMessage")
          : false,
        ...rules,
      }}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...TextFieldProps}
          onChange={onChange || field.onChange}
          label={actualLabel}
          error={Boolean(error)}
          disabled={disabled}
          minRows={minRows}
          maxRows={maxRows}
          sx={sx}
          helperText={Boolean(error) && error?.message}
          multiline={multiline}
          fullWidth={fullWidth}
          data-testid={`textfield-${name}`}
        />
      )}
    />
  );
}
