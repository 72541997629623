import { ItemType } from "core/entities";
import { isValidURL } from "core/utils/isValidURL";
import { exhaustiveCheck } from "core/utils/exhaustiveCheck";
import { PageId, pageItemIdPathMap } from "core/configs/pageIdPathMap";

export function getMyLinksHref<T extends ItemType>(
  itemType: T,
  itemId: T extends "Page" ? PageId : string
): string {
  if (itemType === "Extenso") return `/extenso/${itemId}`;
  if (itemType === "Event") return `/events/${itemId}`;
  if (itemType === "News") return `/news/${itemId}`;
  if (itemType === "DmsLibrary") return `/dms/library/${itemId}`;
  if (itemType === "DmsDocument") return `/dms/document/${itemId}`; // TODO ? SingleDocumentPage not yet implemented
  if (itemType === "External")
    return isValidURL(itemId) ? itemId : `https://${itemId}`;
  if (itemType === "Page") {
    return pageItemIdPathMap[itemId as PageId] ?? "/";
  }
  if (itemType === "CMSKitPage") return `/${itemId}`;
  if (itemType === "CMSKitBlogPost") return `/content/${itemId}`;
  exhaustiveCheck(itemType);
  return "/";
}
