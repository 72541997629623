import { createTheme } from "@mui/material/styles";
import deepmerge from "@mui/utils/deepmerge";
import type { ThemeOptions } from "@mui/material";
import BaseThemeObject from "./BaseThemeObject";

const FixAutoThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#033156",
    },
    secondary: {
      main: "#337ab7",
    },
    background: {
      default: "#E6EBF0",
      paper: "#FAFAFA",
    },
  },
};

export const FixAutoTheme = createTheme(
  deepmerge(BaseThemeObject, FixAutoThemeOptions)
);
