import { ContextValue } from "core/entities";

export function createBrandRegionContextValue(
  brand: string,
  region: string
): ContextValue[] {
  return [
    { key: "brand", value: brand },
    { key: "region", value: region },
  ];
}
