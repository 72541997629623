import { Params } from "core/entities";

export function buildQueryString(params: Params = {}) {
  return Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        if (!value.length) return null;
        return value.map((val) => `${key}=${val}`).join("&");
      }
      if (value instanceof Date) {
        return `${key}=${value.toUTCString()}`;
      }
      return value === "" || value === undefined ? null : `${key}=${value}`;
    })
    .filter(Boolean)
    .join("&");
}
