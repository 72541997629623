import { isMSWEnabled } from "config";
import { FixHubPermissions } from "./permissions";
import { AvailableRoutes } from "./availableRoutes";

interface PagePermission {
  requiredPermissions?: FixHubPermissions[];
  disabled?: boolean;
}

const PaymentRequestSecurity: PagePermission = {
  requiredPermissions: ["Fixhub.PaymentRequests"],
};

const CmsEditorSecurity: PagePermission = {
  requiredPermissions: [
    "CmsKit.BlogPosts.Create",
    "CmsKit.BlogPosts.Update",
    "CmsKit.BlogPosts.Delete",
    "CmsKit.BlogPosts.Publish",
  ],
};

const NewsEventSecurity: PagePermission = {
  requiredPermissions: ["NewsEvents.Articles", "NewsEvents.Categories"],
};

export const routeAccessMap = new Map<AvailableRoutes, PagePermission>([
  ["/[region]/payment-request", PaymentRequestSecurity],
  ["/[region]/payment-request/add", PaymentRequestSecurity],
  ["/[region]/payment-request/[paymentRequestId]", PaymentRequestSecurity],
  ["/[region]/payment-request/[paymentRequestId]/edit", PaymentRequestSecurity],
  ["/[region]/test-page", { disabled: false }],
  ["/[region]/reports/shop-performance", { disabled: !isMSWEnabled }],
  ["/[region]/reports/shop-performance/[id]", { disabled: !isMSWEnabled }],
  ["/[region]/reports/waiting-list", { disabled: !isMSWEnabled }],
  ["/[region]/reports/vehicle-location", { disabled: !isMSWEnabled }],
  ["/[region]/reports/vehicle-location/[id]", { disabled: !isMSWEnabled }],
  ["/[region]/cms-editor", CmsEditorSecurity],
  ["/[region]/cms-editor/[id]", CmsEditorSecurity],
  ["/[region]/cms-editor/create", CmsEditorSecurity],
  [
    "/[region]/shop-hours-management/[[...fnShopId]]",
    {
      requiredPermissions: ["SharedEntities.ShopHours"],
    },
  ],
  ["/[region]/manage/publications/[[...publicationTab]]", NewsEventSecurity],
  [
    "/[region]/manage/publications/articles/create",
    { requiredPermissions: ["NewsEvents.Articles.Create"] },
  ],
  [
    "/[region]/manage/publications/articles/[id]",
    { requiredPermissions: ["NewsEvents.Articles.Edit"] },
  ],
  [
    "/[region]/manage/publications/categories/create",
    { requiredPermissions: ["NewsEvents.Categories.Create"] },
  ],
  [
    "/[region]/manage/publications/categories/[id]",
    { requiredPermissions: ["NewsEvents.Categories.Edit"] },
  ],
  [
    "/[region]/manage/publications/labels/create",
    { requiredPermissions: ["NewsEvents.Categories.Create"] },
  ],
  [
    "/[region]/manage/publications/labels/[id]",
    { requiredPermissions: ["NewsEvents.Categories.Edit"] },
  ],
]);
