import { useRouter } from "next/router";
import { useAPI } from "core/hooks/useAPI";
import { GenericAPIData } from "core/entities";
import { AnyViewableDocumentDto } from "features/DMS/entities";

export interface UseViewableDocumentsOptions {
  skipCount?: number;
  maxResultCount?: number;
  sorting?: "publishTime desc" | "lastModificationTime desc";
  isDocumentModified?: boolean | undefined;
}

export function useViewableDocuments(options?: UseViewableDocumentsOptions) {
  const { locale } = useRouter();
  const {
    skipCount = 0,
    maxResultCount = 5,
    sorting = "publishTime desc",
    isDocumentModified,
  } = options || {};
  const { data, isError, isLoading, mutate } = useAPI<
    GenericAPIData<AnyViewableDocumentDto>
  >({
    // path: "doc-document/viewable-list", // MSW
    path: "doc-document/any-viewable-list", // actual endpoint
    params: {
      skipCount,
      maxResultCount,
      locale,
      sorting,
      isDocumentModified,
    },
  });

  return {
    data,
    isError,
    isLoading,
    mutate,
  };
}
