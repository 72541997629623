import { useCallback } from "react";
import { useRouter } from "next/router";
import { useRegion } from "./useRegion";

/**
 * @param isPush default to `true`
 * @returns
 */
export function useNavigate(isPush = true) {
  const region = useRegion();
  const { locale, push, replace } = useRouter();
  return useCallback(
    (href: string, regionless = false) => {
      const actualHref =
        href.startsWith("/") && !regionless
          ? `/${locale}${region ? `/${region}` : ""}${href}`
          : href;
      if (isPush) {
        push(actualHref);
        return;
      }
      replace(actualHref);
    },
    [isPush, locale, push, region, replace]
  );
}
