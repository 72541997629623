import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { FormEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useRouter } from "next/router";
import { useNavigate } from "core/hooks/useNavigate";

export default function SearchBar() {
  const { events } = useRouter();
  const { t } = useTranslation("Fixhub");
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  function submitHandler(e: FormEvent) {
    e.preventDefault();
    navigate(`/search?filterText=${filterText}`);
  }

  // clear search bar if navigating to any page other than search
  useEffect(() => {
    const eventArgs: Parameters<typeof events.on> = [
      "routeChangeStart",
      (url: string) => {
        if (!/search/.test(url)) {
          setFilterText("");
        }
      },
    ];
    events.on(...eventArgs);
    return () => {
      events.off(...eventArgs);
    };
  }, [events]);

  return (
    <Box component="form" flex={1} onSubmit={submitHandler}>
      <Input
        fullWidth
        placeholder={t("Next:Core:SearchBar.placeholder")}
        size="small"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        sx={{
          bgcolor: "background.default",
          borderRadius: "4px",
          flex: 1,
          py: 0.5,
          px: 2,
          "& .MuiInputBase-input": { color: "primary.main" },
          "&& ::placeholder": { opacity: 1, color: "text.secondary" },
        }}
        disableUnderline
        endAdornment={
          <InputAdornment
            sx={{ "&:hover": { cursor: "pointer" } }}
            position="end"
          >
            <IconButton onClick={submitHandler}>
              <SearchIcon sx={{ color: "primary.main" }} />
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  );
}
