import { useRouter } from "next/router";
import { useAPIAll } from "core/hooks/useAPIAll";
import { useCallback, useMemo } from "react";
import { IDocCategoryDto } from "features/DMS/entities";
import { GenericAPIData } from "core/entities";
import { MutatorCallback, MutatorOptions } from "swr";
import { useContainerProcessor } from "./useContainerProcessor";
import { useLibraryQueryStateSorting } from "./useLibraryQueryStateSorting";

interface UseCategoriesOptions {
  parentId: string;
  shouldFetch?: boolean;
  mode: "view" | "manage";
  canManageLibraryContents: boolean | undefined;
}
export function useCategories({
  mode,
  parentId,
  canManageLibraryContents,
  shouldFetch = true,
}: UseCategoriesOptions) {
  const [queryStateSorting] = useLibraryQueryStateSorting();
  const { locale = "en" } = useRouter();
  const processCategory = useContainerProcessor();
  const params = { parentId, sorting: queryStateSorting, locale };
  const manageKey = canManageLibraryContents
    ? {
        path: "/api/app/doc-category/manageable-list",
        params,
      }
    : null;
  const viewKey = {
    path: "/api/app/doc-category/viewable-list",
    params,
  };
  const {
    data: manageData,
    isLoading: isManageDataLoading,
    isError: isManageDataError,
    mutate: manageMutate,
  } = useAPIAll<GenericAPIData<IDocCategoryDto>>(
    shouldFetch ? manageKey : null
  );
  const {
    data: viewData,
    isLoading: isViewDataLoading,
    isError: isViewDataError,
    mutate: viewMutate,
  } = useAPIAll<GenericAPIData<IDocCategoryDto>>(shouldFetch ? viewKey : null);

  const mutate = useCallback(
    (
      data?:
        | GenericAPIData<IDocCategoryDto>
        | Promise<GenericAPIData<IDocCategoryDto>>
        | MutatorCallback<GenericAPIData<IDocCategoryDto>>,
      opts?: boolean | MutatorOptions<GenericAPIData<IDocCategoryDto>>
    ) => {
      viewMutate();
      if (data && opts) {
        manageMutate(data, opts);
        return;
      }
      if (data) {
        manageMutate(data);
        return;
      }
      manageMutate();
    },
    [viewMutate, manageMutate]
  );

  const { data, isLoading, isError } = useMemo(() => {
    if (mode === "manage") {
      const { items, totalCount } = manageData ?? {};
      const processedItems = items?.map(processCategory) || [];
      return {
        data:
          isManageDataLoading || isManageDataError
            ? undefined
            : { items: processedItems, totalCount },
        isLoading: isManageDataLoading,
        isError: isManageDataError,
      };
    }

    const { items, totalCount } = viewData ?? {};
    const processedItems = items?.map(processCategory) || [];
    return {
      data:
        isViewDataLoading || isViewDataError
          ? undefined
          : { items: processedItems, totalCount },
      isLoading: isViewDataLoading,
      isError: isViewDataError,
    };
  }, [
    mode,
    processCategory,
    manageData,
    isManageDataLoading,
    isManageDataError,
    isViewDataError,
    isViewDataLoading,
    viewData,
  ]);
  return {
    data,
    isLoading,
    isError,
    mutate,
  };
}
