import { useAuth } from "oidc-react";
import { useEffect } from "react";

/**
 * A React component that synchronizes the user's authentication token to the authorization_token cookie.
 *
 * This component listens for the `addUserLoaded` event from the `userManager` object provided by the `useAuth` hook.
 * When the event is triggered, the component retrieves the access token and expiration time from the user data,
 * and sets a cookie named `authorization_token` with the access token and expiration time.
 *
 * authorization_token cookie is used to authenticate the user when accessing secure files from the API via anchor tags.
 *
 * The component returns `null`, so it does not render anything in the DOM.
 */
export function CookieSync() {
  const auth = useAuth();
  useEffect(
    () =>
      auth.userManager.events.addUserLoaded((userData) => {
        const { access_token: accessToken, expires_at: expiresAt } = userData;

        if (!expiresAt) {
          document.cookie = `authorization_token=${accessToken};path=/;SameSite=strict;`;
          return;
        }

        const expires = new Date(expiresAt * 1000).toUTCString();
        document.cookie = `authorization_token=${accessToken};path=/;SameSite=strict;expires=${expires}`;
      }),
    [auth.userManager.events]
  );
  return null;
}
