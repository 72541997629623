import { useMemo } from "react";
import { useAPI } from "core/hooks/useAPI";
import { IDocDocumentDto } from "features/DMS/entities";
import { useDocumentProcessor } from "./useDocumentProcessor";

export function useDocumentById(documentId?: string) {
  const processDocument = useDocumentProcessor();
  const {
    data: document,
    isError,
    isLoading,
    mutate,
  } = useAPI<IDocDocumentDto>(`doc-document/${documentId}`);
  const data = useMemo(() => {
    if (isError || isLoading || !document) return undefined;
    return processDocument(document);
  }, [document, processDocument, isError, isLoading]);

  return {
    data,
    isError,
    isLoading,
    mutate,
  };
}
