import { ReactNode, useMemo } from "react";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { ExtensoTheme } from "core/theme";
import { BrandCode } from "core/entities";
import { useRouter } from "next/router";
import {
  enUS as coreEn,
  frFR as coreFr,
  deDE as coreDe,
  esES as coreEs,
} from "@mui/material/locale";
import {
  enUS as gridEn,
  frFR as gridFr,
  deDE as gridDe,
  esES as gridEs,
} from "@mui/x-data-grid-pro";
import {
  enUS as datePickersEn,
  frFR as datePickersFr,
  deDE as datePickersDe,
  esES as datePickersEs,
} from "@mui/x-date-pickers-pro";
import { deepmerge } from "@mui/utils";
import { useBrand } from "core/hooks/useBrand";
import { useExtensoToken } from "core/hooks/useExtensoToken";

const localeMap = new Map([
  ["en", deepmerge(deepmerge(coreEn, gridEn), datePickersEn)],
  ["fr", deepmerge(deepmerge(coreFr, gridFr), datePickersFr)],
  ["de", deepmerge(deepmerge(coreDe, gridDe), datePickersDe)],
  ["es", deepmerge(deepmerge(coreEs, gridEs), datePickersEs)],
]);

export function MUIThemeProvider({
  children,
  brandCode,
}: {
  children: ReactNode;
  brandCode?: BrandCode;
}) {
  const { theme } = useBrand(brandCode);
  const { locale } = useRouter();

  const localizationResource = localeMap.get(locale ?? "en")!;

  const { token: extensoToken } = useExtensoToken();
  const currentTheme = useMemo(() => {
    const baseTheme = extensoToken ? ExtensoTheme : theme;
    const themeWithLocalization = createTheme(baseTheme, localizationResource);
    const responsiveTypographyTheme = responsiveFontSizes(
      themeWithLocalization
    );
    return responsiveTypographyTheme;
  }, [extensoToken, theme, localizationResource]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
}
