import { ListOfContexts } from "core/entities/permissionContextDefs";

/**
 * @param {ListOfContexts} currentContext - Context of the user.
 * @param {ListOfContexts} permissionContext - Context to test against
 * @returns {boolean} Whether the permissionContext includes the currentContext or not
 */
export function isSameContext(
  currentContext: ListOfContexts,
  permissionContext: ListOfContexts
): boolean {
  const permissionContextMap = new Map(
    permissionContext.map(({ key, value }) => [key, value])
  );
  return currentContext.every((curr) => {
    const permContextValue = permissionContextMap.get(curr.key);
    if (!permContextValue) return false;
    return curr.value.toLowerCase() === permContextValue.toLowerCase();
  });
}
