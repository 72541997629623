import { createTheme } from "@mui/material/styles";
import type { ThemeOptions } from "@mui/material";
import deepmerge from "@mui/utils/deepmerge";
import BaseThemeObject from "./BaseThemeObject";

const DefaultThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#003da5",
    },
    secondary: {
      main: "#ef3e43",
    },
    warning: {
      main: "#ef3e43",
    },
  },
};

export const DefaultTheme = createTheme(
  deepmerge(BaseThemeObject, DefaultThemeOptions)
);
