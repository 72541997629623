import Typography, { TypographyProps } from "@mui/material/Typography";
import type { APIError } from "core/entities";
import { useTranslation } from "react-i18next";

export interface ErrorComponentProps extends TypographyProps {
  apiError?: APIError;
  message?: string;
}

export function ErrorComponent({
  apiError,
  message,
  ...rest
}: ErrorComponentProps) {
  const { t } = useTranslation();
  if (message) {
    return (
      <Typography variant="h5" color="error.main" {...rest}>
        {message}
      </Typography>
    );
  }
  if (apiError && apiError.message) {
    const { status, info, message: genericErrorMessage } = apiError;
    const statusText = status ? `${status}: ` : "";
    const errorCode = info?.error?.code;
    const errorText =
      (errorCode ? t(errorCode) : apiError.info?.error?.message) ||
      genericErrorMessage;
    const errorMessage = `${statusText}${errorText}`;
    return (
      <Typography variant="h5" color="error.main" {...rest}>
        {errorMessage}
      </Typography>
    );
  }
  return (
    <Typography variant="h3" color="error.main" {...rest}>
      {t("Next:Core:Error")}
    </Typography>
  );
}
