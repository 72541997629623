import { DependencyList, useCallback } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useToaster } from "./useToaster";
import { useTrackException } from "./useTrackException";

export type UsePromiseCallbackTrackingOptions = {
  successMessage?: string;
  errorMessage?: string;
  componentName: string;
  functionName: string;
  throwOnError?: boolean;
};

/**
 * A hook that wraps a promise-based callback function and provides error handling, exception tracking, and toast notifications.
 * @param callback The promise-based callback function to wrap.
 * @param deps The dependency array to pass to the `useCallback` hook.
 * @param options The options object that contains the success and error messages, component and function names, and error handling behavior.
 * @returns A memoized version of the wrapped callback function.
 * @example
 * ```
 *  const { data } = useUserInfo();
 *  const id = data?.id;
 *  const put = useAPIPutJSONNoContent();
 *  const changePassword = usePromiseCallbackTracking(
 *    async (password: string) => {
 *      if (!id) return;
 *      await put(`/api/identity/users/${id}/change-password`, {
 *        newPassword: password,
 *      });
 *    },
 *    [put, id],
 *    {
 *      componentName: "Password Form",
 *      functionName: "changePassword",
 *      successMessage: "Password changed successfully",
 *      errorMessage: "An Error occurred while changing the password",
 *    }
 *  );
 *
 *  async function onValidSubmit({ password }: { password: string }) {
 *    await changePassword(password);
 *  }
 * ```
 */

export function usePromiseCallbackTracking<TArgs extends any[], TReturn>(
  callback: (...args: TArgs) => Promise<TReturn>,
  deps: DependencyList,
  options: UsePromiseCallbackTrackingOptions
) {
  const { successToast, errorToast } = useToaster();
  const trackException = useTrackException();
  return useCallback(
    async (...args: TArgs) => {
      try {
        const result = await callback(...args);
        if (options?.successMessage) {
          successToast(options.successMessage);
        }
        return result;
      } catch (err) {
        console.error(err);
        trackException({
          exception: err as Error,
          severityLevel: SeverityLevel.Error,
          properties: {
            componentName: options?.componentName,
            functionName: options?.functionName,
            args,
          },
        });
        if (options?.errorMessage) {
          errorToast(options.errorMessage);
        }
        if (options.throwOnError) {
          throw err;
        }
        return undefined;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...deps,
      options?.successMessage,
      options?.componentName,
      options?.functionName,
      options?.errorMessage,
      successToast,
      trackException,
      errorToast,
    ]
  );
}
