import { useUserLog } from "core/hooks/useUserLog";
import React, { useState, MouseEvent } from "react";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useAuth } from "oidc-react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { copyTranslationsToClipboard } from "i18n.config";
import { isDevelopment } from "config";
import { buildQueryString } from "core/utils/buildQueryString";
import { exhaustiveCheck } from "core/utils/exhaustiveCheck";
import { useToaster } from "core/hooks/useToaster";
import { useNavigate } from "core/hooks/useNavigate";
import { useRegion } from "core/hooks/useRegion";
import { useToken } from "core/hooks/useToken";
import { useAvailableRegions } from "core/hooks/useAvailableRegions";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { useUserTokenInfo } from "core/hooks/useUserTokenInfo";
import { AvailableRegionMenuItem } from "./AvailableRegionMenuItem";
import { UserAvatar } from "./UserAvatar";
import { useAvailableLocales } from "../hooks/useAvailableLocales";
import { Link } from "./Link";

export type MenuOption = "logout" | "region-select" | "user-preferences";
type DevMenuOption = "copy-translations" | "test-page" | "theme-inspect-page";

function useUserMenu() {
  const { successToast, errorToast } = useToaster();
  const { addLog } = useUserLog();
  const { push } = useRouter();
  const navigate = useNavigate();
  const { signOutRedirect } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  function handleOpenUserMenu(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }
  async function handleCloseUserMenu(
    option: MenuOption | DevMenuOption | null
  ) {
    if (!option) {
      setAnchorEl(null);
      return;
    }
    switch (option) {
      case "logout":
        await addLog("Page", "logout");
        await signOutRedirect();
        break;
      case "region-select":
        push("/region-select");
        break;
      case "user-preferences":
        navigate("/user-preferences");
        break;
      case "copy-translations":
        try {
          copyTranslationsToClipboard();
          successToast("Translations copied to clipboard");
        } catch (e) {
          errorToast(
            "Translations failed to copy to clipboard, are you browsing from a secure origin?"
          );
        }
        break;
      case "test-page":
        navigate("/test-page");
        break;
      case "theme-inspect-page":
        navigate("/theme-inspect");
        break;
      default:
        exhaustiveCheck(option);
        break;
    }
    setAnchorEl(null);
  }
  return { anchorEl, handleOpenUserMenu, handleCloseUserMenu, setAnchorEl };
}

interface UserMenuProps {
  menuItems?: MenuOption[];
}

function ExtensoLoginMenuItem() {
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:UserMenu" });
  const region = useRegion();
  const { locale: lg } = useRouter();
  const token = useToken();
  const page = "payment-request";
  const href = `/extenso-redirect?${buildQueryString({
    region,
    lg,
    token,
    page,
    path: page,
  })}`;
  return (
    <Link color="text.primary" href={href} target="_blank" regionless>
      <MenuItem>{t("option.extensoLogin")}</MenuItem>
    </Link>
  );
}

export function UserMenu({
  menuItems = ["region-select", "logout", "user-preferences"],
}: UserMenuProps) {
  const availableRegions = useAvailableRegions();
  const currentRegion = useRegion();
  const { anchorEl, setAnchorEl, handleCloseUserMenu, handleOpenUserMenu } =
    useUserMenu();
  const { isDesktop } = useBreakpoints();
  const { locale, pathname, push, asPath, query } = useRouter();
  const availableLocales = useAvailableLocales();
  const { nickname, given_name: givenName, name } = useUserTokenInfo() || {};
  const displayName = nickname ?? name ?? givenName ?? "";
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:UserMenu" });
  const { t: tCore } = useTranslation("Fixhub");

  function selectLanguage(nextLocale: string) {
    return () => {
      setAnchorEl(null);
      push({ pathname, query }, asPath, { locale: nextLocale });
    };
  }

  return (
    <>
      <Button
        variant="text"
        sx={{ color: "primary.contrastText" }}
        onClick={handleOpenUserMenu}
        color="secondary"
      >
        <UserAvatar />
        {isDesktop && (
          <Typography
            px={1}
            textTransform="none"
            display="flex"
            alignItems="center"
          >
            {displayName}
            <ArrowDropDownIcon />
          </Typography>
        )}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        onClose={async () => {
          await handleCloseUserMenu(null);
        }}
      >
        {!isDesktop && (
          <Divider textAlign="center">
            <Typography color="text.secondary" variant="overline">
              {displayName}
            </Typography>
          </Divider>
        )}
        {menuItems.map((option) => (
          <MenuItem
            key={option}
            onClick={async () => {
              await handleCloseUserMenu(option);
            }}
          >
            {t(`option.${option}`)}
          </MenuItem>
        ))}
        {isDevelopment && [
          <Divider key="devOptionsDivider" textAlign="center">
            <Typography color="text.secondary" variant="overline">
              {t("devOptions")}
            </Typography>
          </Divider>,
          <MenuItem
            key="copy-translations"
            onClick={async () => {
              await handleCloseUserMenu("copy-translations");
            }}
          >
            {t(`option.copy-translations`)}
          </MenuItem>,
          <MenuItem
            key="test-page"
            onClick={async () => {
              await handleCloseUserMenu("test-page");
            }}
          >
            {t(`option.test-page`)}
          </MenuItem>,
          <MenuItem
            key="theme-inspect-page"
            onClick={async () => {
              await handleCloseUserMenu("theme-inspect-page");
            }}
          >
            {t(`option.theme-inspect-page`)}
          </MenuItem>,
          <ExtensoLoginMenuItem key="extenso-login" />,
        ]}
        <Divider textAlign="center">
          <Typography color="text.secondary" variant="overline">
            {t("language")}
          </Typography>
        </Divider>
        {availableLocales?.map((selectedLocale) => (
          <MenuItem
            selected={locale === selectedLocale}
            key={selectedLocale}
            onClick={selectLanguage(selectedLocale)}
          >
            {tCore(`Next:Core:LanguageCodes.${selectedLocale}`)}
          </MenuItem>
        ))}
        {availableRegions.length > 1 && (
          <Divider textAlign="center">
            <Typography color="text.secondary" variant="overline">
              {t("region")}
            </Typography>
          </Divider>
        )}
        {availableRegions.length > 1 &&
          availableRegions?.map((availableRegion) => (
            <AvailableRegionMenuItem
              selected={currentRegion === availableRegion}
              region={availableRegion}
              pathname={pathname}
              query={query}
              locale={locale}
              onClick={() => {
                handleCloseUserMenu(null);
              }}
              label={tCore(`Region:RegionName.${availableRegion}`)}
              key={availableRegion}
            />
          ))}
      </Menu>
    </>
  );
}
