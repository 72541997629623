import FetchBackend from "i18next-fetch-backend";
import i18n from "i18next";
import { API_URL, isDevelopment, isBrowser } from "config";
import { initReactI18next } from "react-i18next";
import { copyTextToClipboard } from "core/utils/copyTextToClipboard";

export const MISSING_TRANSLATION_LOCAL_STORAGE_KEY = "missingKeys";

const shouldHandleMissingKeys = isBrowser && isDevelopment;

if (shouldHandleMissingKeys) {
  localStorage.removeItem(MISSING_TRANSLATION_LOCAL_STORAGE_KEY);
}

export function copyTranslationsToClipboard() {
  const missingKeyObject: { en: Record<string, string> } = JSON.parse(
    localStorage.getItem(MISSING_TRANSLATION_LOCAL_STORAGE_KEY) || '{"en":{}}'
  );
  const formattedString = Object.keys(missingKeyObject.en)
    .map((key) => {
      const value = key.split(".").at(-1) || key;
      return `"${key}":"${value}"`;
    })
    .join(",\n");
  copyTextToClipboard(`${formattedString},`);
}

function getResourceStorageKey(url: string) {
  const resourceURL = new URL(url);
  const locale = resourceURL.searchParams.get("locale")!;
  const resource = resourceURL.searchParams.get("resource")!;
  return {
    key: `localization?locale=${locale}&resource=${resource}`,
    locale,
    resource,
  };
}

async function fetchAndUpdateCache(url: string) {
  const res = await fetch(url);
  const { key, locale, resource } = getResourceStorageKey(url);
  if (!res.ok)
    throw new Error(`failed to fetch localization resource at: ${url}`);
  const resJsonString = await res.text();
  localStorage.setItem(key, resJsonString);
  i18n.addResourceBundle(
    locale,
    resource,
    JSON.parse(resJsonString),
    false,
    true
  );
  return resJsonString;
}

export const namespaces = [
  "Fixhub",
  "PaymentRequest",
  "NewsEvents",
  "DocumentManagementSystem",
] as const;

i18n
  .use(FetchBackend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: (lng: string, ns: string) => {
        const { href } = new URL(
          `localization?locale=${lng}&resource=${ns}`,
          API_URL
        );
        return href;
      },
      fetch: async (url: string) => {
        const cachedString = localStorage.getItem(
          getResourceStorageKey(url).key
        );
        try {
          if (cachedString) {
            // we want to catch the potential error of calling fetchAndUpdateCache here,
            // but still return the cached string.
            setTimeout(async () => {
              try {
                await fetchAndUpdateCache(url);
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
              }
            }, 0);
            return new Response(cachedString);
          }
          const resourceString = await fetchAndUpdateCache(url);
          return new Response(resourceString);
        } catch {
          return new Response("", { status: 500 });
        }
      },
    },
    fallbackLng: isDevelopment ? false : "en",
    fallbackNS: ["Fixhub"],
    debug: false,
    defaultNS: "Fixhub",
    ns: namespaces,
    lng: "en",
    nsSeparator: ";",
    saveMissing: isDevelopment,
    missingKeyHandler: (lng, ns, key) => {
      const localStorageValue = localStorage.getItem(
        MISSING_TRANSLATION_LOCAL_STORAGE_KEY
      );
      const missingKeyObj =
        typeof localStorageValue === "string"
          ? JSON.parse(localStorageValue)
          : {};
      if (String(lng) in missingKeyObj) {
        missingKeyObj[String(lng)][key] = key;
      } else {
        missingKeyObj[String(lng)] = {};
        missingKeyObj[String(lng)][key] = key;
      }
      // eslint-disable-next-line no-console
      console.log(
        `%cMissing Key [${String(lng)}]: ${key}`,
        "color:red;font-weight:bold;",
        missingKeyObj
      );
      localStorage.setItem(
        MISSING_TRANSLATION_LOCAL_STORAGE_KEY,
        JSON.stringify(missingKeyObj)
      );
    },
    interpolation: {
      escapeValue: false,
      format(value, format, lng, edit) {
        const formatParams = edit?.formatParams;
        if (format === "number") {
          return new Intl.NumberFormat(lng, formatParams).format(value);
        }
        if (format === "datetime") {
          return new Intl.DateTimeFormat(lng, formatParams).format(value);
        }
        if (format === "currency") {
          return new Intl.NumberFormat(lng, {
            style: "currency",
            currency: "CAD",
            currencyDisplay: "narrowSymbol",
          }).format(value);
        }
        return value;
      },
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
