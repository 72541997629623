import { ThemeOptions } from "@mui/material/";
import { createTheme } from "@mui/material/styles";
import deepmerge from "@mui/utils/deepmerge";
import BaseThemeObject from "./BaseThemeObject";

const ProColorThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#006BB6",
      dark: "#1E5B8F",
    },
    secondary: {
      main: "#EF3E42",
    },
    warning: {
      main: "#F57C00",
    },
    success: {
      main: "#388E3C",
    },
    error: {
      main: "#EF3E42",
    },
    background: {
      default: "#E6EBF0",
      defaultDark: "#dbe8f3",
      defaultDarker: "#c0d8ea",
      paper: "#FAFAFA",
    },
  },
};

export const ProColorTheme = createTheme(
  deepmerge(BaseThemeObject, ProColorThemeOptions)
);
