import type { ThemeOptions } from "@mui/material";
import type { GridLocaleText } from "@mui/x-data-grid-pro";

declare module "@mui/material/styles" {
  interface Components {
    MuiDataGrid?: {
      defaultProps: {
        localeText: Partial<GridLocaleText>;
      };
    };
  }
  type ThemePaletteIncrements = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90;
  interface Palette {
    lighten?: any;
    darken?: any;
    favorite?: string;
  }
  interface PaletteColor {
    lightest?: string;
    lighter?: string;
    darker?: string;
    darkest?: string;
  }
  interface SimplePaletteColorOptions {
    lightest?: string;
    lighter?: string;
    darker?: string;
    darkest?: string;
  }
  interface TypeBackground {
    defaultDark?: string;
    defaultDarker?: string;
  }
  interface PaletteOptions {
    lighten?: Record<ThemePaletteIncrements, React.CSSProperties["color"]>;
    darken?: Record<ThemePaletteIncrements, React.CSSProperties["color"]>;
    favorite?: string;
  }
}

const BaseThemeObject: ThemeOptions = {
  palette: {
    primary: {
      lightest: "#dbe8f4",
      lighter: "#aec0d1",
      main: "#006BB6",
      darker: "#004a80",
      darkest: "#003c66",
    },
    background: {
      defaultDark: "#dbe8f3",
      defaultDarker: "#c0d8ea",
    },
    darken: {
      10: "hsla(0,100%,0%,0.10)",
      20: "hsla(0,100%,0%,0.20)",
      30: "hsla(0,100%,0%,0.30)",
      40: "hsla(0,100%,0%,0.40)",
      50: "hsla(0,100%,0%,0.50)",
      60: "hsla(0,100%,0%,0.60)",
      70: "hsla(0,100%,0%,0.70)",
      80: "hsla(0,100%,0%,0.80)",
      90: "hsla(0,100%,0%,0.90)",
    },
    lighten: {
      10: "hsla(0,100%,100%,0.10)",
      20: "hsla(0,100%,100%,0.20)",
      30: "hsla(0,100%,100%,0.30)",
      40: "hsla(0,100%,100%,0.40)",
      50: "hsla(0,100%,100%,0.50)",
      60: "hsla(0,100%,100%,0.60)",
      70: "hsla(0,100%,100%,0.70)",
      80: "hsla(0,100%,100%,0.80)",
      90: "hsla(0,100%,100%,0.90)",
    },
    favorite: "#ffeb2e",
  },
  shape: {
    borderRadius: 4,
  },
};

export default BaseThemeObject;
