import type { ListOfContexts } from "core/entities/permissionContextDefs";
import { useMemo, createContext, ReactNode } from "react";
import { useBrandRegionCode } from "core/hooks/useBrandRegionCode";
import { getBrandCode } from "core/utils/getBrandCode";
import { createBrandRegionContextValue } from "core/utils/createBrandRegionContextValue";

export const CurrentBrandRegionContext = createContext<ListOfContexts>([]);
interface UserContextProviderProps {
  children: ReactNode;
}

export default function CurrentBrandRegionContextProvider({
  children,
}: UserContextProviderProps) {
  const brand = getBrandCode();
  const brandRegionCode = useBrandRegionCode();
  const userContextValue = useMemo(() => {
    if (!brand || !brandRegionCode) return [];
    return createBrandRegionContextValue(brand, brandRegionCode);
  }, [brand, brandRegionCode]);

  return (
    <CurrentBrandRegionContext.Provider value={userContextValue}>
      {children}
    </CurrentBrandRegionContext.Provider>
  );
}
